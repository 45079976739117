<template>
  <div class="container">
    <b-overlay
      variant="white"
      :show="isLoading"
      rounded="sm"
    >
      <b-card class="p-1">

        <b-tabs
          id="profile"
          fill
          vertical
          nav-wrapper-class="nav-vertical"
        >

          <b-tab
            v-if="user_group.account_type === this.$AccountTypes.APPLICANT"
          >
            <template #title>
              <feather-icon icon="MailIcon" />
              <span>Communication Preferences</span>
            </template>
            <b-row>
              <b-form-checkbox
                v-model="mail_configuration.job_alerts"
                class="ml-6"
              >Do you want to receive job alerts?</b-form-checkbox>
            </b-row>
            <!-- <div class="vx-row my-6 ml-2">
              <b-form-checkbox class="ml-6" v-model="mail_configuration.promotions"
                >Do you want to receive offers and promotions from HeadHunters
                HQ?</b-form-checkbox
              >
            </div> -->
            <b-row>
              <b-button
                variant="primary"
                class="mt-1"
                @click="updateConfigInfo"
              >
                Save Changes
              </b-button>
            </b-row>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="EyeOffIcon" />
              <span>Privacy Preferences</span>
            </template>
            <b-row class="d-flex flex-column"> 
              <h6
                class="my-1 ml-6 font-weight-bolder"
                style="font-weight: 500;"
              >
                As we understand the importance of your privacy, you may opt in
                for the following options:
              </h6>
              <div class="ml-1">
                <b-form-checkbox
                  v-model="privacy_configuration.mask_email"
                  class="my-1"
                >Receive Marketing/Outreach Emails</b-form-checkbox>

                <b-alert
                  variant="primary"
                  show
                >
                  <div class="alert-body">
                    <div>
                      <span>
                        Note: Do note that Transactional emails that are required for us to continue providing the service to you will still be opt-in
                      </span>
                    </div>
                  </div>
                </b-alert>
              </div>
            </b-row>

            <b-row class="mt-2 float-right">
              <b-button
                variant="primary"
                @click="updateConfigInfo"
              >
                Save Changes
              </b-button>
            </b-row>
          </b-tab>

          <b-tab
            title="Manage Your Data"
            icon-pack="feather"
            icon="icon-folder"
          >
            <template #title>
              <feather-icon icon="FolderIcon" />
              <span>Manage Your Data</span>
            </template>
            <b-alert
              show
              variant="warning"
              class="my-1"
              icon-pack="feather"
              icon="icon-info"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Before deleting your account, make sure you have downloaded your
                  data by clicking `Download My Data` button below.</span>
              </div>

            </b-alert>
            <b-row class="d-flex justify-content-start">
              <b-col>
                <b-button
                  disabled
                  variant="warning"
                >
                  Download My Data
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  disabled
                  variant="danger"
                >
                  Deactivate Account (Contact DSG)
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <!-- <b-tab
            title="Notes"
            icon-pack="feather"
            icon="icon-archive"
          >

           <template #title>
              <feather-icon icon="ArchiveIcon" />
              <span>Notes</span>
            </template>
        </b-tab> -->

        </b-tabs>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BTab,
  BTabs,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      activeTab: 0,
      mail_configuration: {
        job_alerts: false,
        promotions: false,
      },
      privacy_configuration: {
        mask_email: false,
        mask_phone_number: false,
      },
      user_group: [],
      account_type: 'applcant',
      isLoading: false,
    }
  },
  watch: {
  },
  created() {
    const { token } = this.$store.state.auth.ActiveUser || {}
    this.$http.defaults.headers.common.Authorization = `Token ${token}`
    this.getConfigInfo()
    this.account_type = this.$store.state.auth.ActiveUser.account_type
  },
  methods: {
    updateConfigInfo() {
      this.isLoading = true
      const all_data = {
        privacy_configuration: this.privacy_configuration,
        mail_configuration: this.mail_configuration,
      }
      this.$http
        .put('/api/account-config', all_data)
        .then(response => {
          const { config } = response.data

          if (this.$isNotEmpty()) {
            this.mail_configuration = config.mail_configuration
            this.privacy_configuration = config.privacy_configuration
          }

          this.$toastSuccess('Operation Success', 'Settings updated successfully')

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },

    getConfigInfo() {
      this.isLoading = true
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data

          this.mail_configuration = config.mail_configuration
          this.privacy_configuration = config.privacy_configuration
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
  },
}
</script>
